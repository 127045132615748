<template>
  <div class="auth-wrapper auth-v1 px-2">
    <div class="dark-light-icon bookmark-wrapper align-items-center flex-grow-1 d-none d-lg-flex">
        <!-- <dark-Toggler class="d-none d-lg-block" /> -->
    </div>
    <div class="auth-inner py-2">

      <!-- Phone Confirmation -->
      <b-card class="mb-0">
        <b-link :to="{ name: 'login' }" class="brand-logo">
          <h2 class="brand-text text-primary ml-1">
            FiqOn
          </h2>
          <b-img
            fluid
            class="fiqon-logo" 
            :class="logoUrl ? '' : 'light-logo' "
            :src="logoImg"
            alt="Phone Confirm"
          />
        </b-link>

        <b-card-title class="mb-1">
          Verify your Phone 📱
        </b-card-title>
        <b-card-text class="mb-2">
          A confirmation code was sent to your phone number.
        </b-card-text>

        <!-- form -->
        <validation-observer ref="codeValidation">
          <b-form
            class="auth-forgot-password-form mt-2"
            @submit.prevent="validationForm"
          >
            <!-- Confirmation Code -->
            <b-form-group
              label="What's your code?"
              label-for="phone-confirmation-code"
            >
              <validation-provider
                #default="{ errors }"
                name="confirmation code"
                rules="required"
              >
                <b-form-input
                  id="phone-confirmation-code"
                  v-model="userConfirmationCode"
                  :state="errors.length > 0 ? false:null"
                  name="phone-confirmation-code"
                  placeholder="- - - - - - "
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <!-- submit button -->
            <b-button
              variant="primary"
              block
              type="submit"
            >
              Validate Phone
            </b-button>
             <b-button
              variant="secondary"
              block
              id="resend-phone"
              @click="sendPhoneConfirmation()"
              :disabled="timerEnabled"
            >
              Resend Code
              {{ timerEnabled ? `${$t("common.terms.in")} ${timerCount} s` : "" }}
            </b-button>
          </b-form>
        </validation-observer>

        <b-card-text class="text-center mt-2">
          <b-link @click="backToLogin">
            <feather-icon icon="ChevronLeftIcon" /> 
            Back to login
          </b-link>
        </b-card-text>

      </b-card>
    </div>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BCard, BLink, BCardText, BCardTitle, BFormGroup, BFormInput, BForm, BButton, BImg
} from 'bootstrap-vue'
import { required, email } from '@validations'
import DarkToggler from '@core/layouts/components/app-navbar/components/DarkToggler.vue'
import store from '@/store/index'
import Animation from '/src/layouts/components/Animation.vue'
import custom from "@/custom";
import { makeToast } from '@/layouts/components/Popups'

export default {
  components: {
    BCard,
    BLink,
    BCardText,
    BCardTitle,
    BFormGroup,
    BFormInput,
    BButton,
    BForm,
    BImg,
    DarkToggler,
    Animation,
    ValidationProvider,
    ValidationObserver,
  },
  data() {
    return {
      userConfirmationCode: '',
      // validation
      required,
      logoImg: require('@/assets/images/logo/logo.svg'),
      timerEnabled: false,
      timerCount: 30,
    }
  },
  computed:{
    logoUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        return false
      }
      return true
    },
  },
   watch: {
    timerEnabled(value) {
      if (value) {
        setTimeout(() => {
          this.timerCount--;
          if(this.timerCount == 0) {
            this.timerEnabled = false;
          }
        }, 1000);
      }
    },

    timerCount: {
      handler(value) {
        if (value > 0 && this.timerEnabled) {
          setTimeout(() => {
            this.timerCount--;
            if(this.timerCount == 0) {
              this.timerEnabled = false;
            }
          }, 1000);
        }
      },
    },
  },
  methods: {
    sendPhoneConfirmation() {
      this.$store.dispatch('sendPhoneConfirmation')
        .then(() => {
          this.timerEnabled = true
          makeToast(custom.infoMessages.verify_phone)  
        }).catch(error => {
          makeToast(custom.errorMessages[error.code])
        })
    },
    validationForm() {
      this.$refs.codeValidation.validate().then(success => {
        if (success) {
          this.$store.dispatch('phoneConfirmation', this.userConfirmationCode )
            .then(response => {
              if(response){
                
                this.$router.push( { name: 'home' }).then(() => {
                  makeToast(custom.successMessages.phone_verified)
                })
              }else{
                alert('Oops, there was an error in vuex')
              }
          }).catch(error => {
            makeToast(custom.errorMessages[error.code])
          })
        }
      })
    },
    backToLogin() {
      localStorage.clear();
      this.$router.push({ name: "login" });
    },
  },
}
</script>

<style lang="scss">
  @import '@core/scss/vue/pages/page-auth.scss';

.fiqon-logo-welcome{
  height: 17px;
}
.brand-name{
  color: $primary;
}
</style>